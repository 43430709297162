#contact-page {
  max-width: var(--maxWidthSections);
  margin: auto;
  position: relative;
  margin-top: 70px;
  margin-bottom: 70px;
}

/*************************************************/
/*************** Contact Text ********************/
/*************************************************/
#contact__contact-container {
  height: 100px;
  width: 70%;
  background-color: var(--blueColour);
  /* -webkit-filter: url(#static-noise-contact);
  filter: url(#static-noise-contact); */
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  z-index: 2;
  transition: all 0.3s ease;
}
#contact__contact-text {
  font-weight: 300;
  margin-right: 30px;
}


/************************************************/
/******************* Social *********************/
/************************************************/
#contact__social-container {
  height: 100px;
  width: 70%;
  margin-left: 30%;
  background-color: var(--purpleColour);
  /* -webkit-filter: url(#static-noise-contact);
  filter: url(#static-noise-contact); */
  position: relative;
  transition: all 0.3s ease;
}
#contact__social-container-box {
  width: 57%;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.contact__social-icon-s {
  font-size: 40px;
  color: var(--lightColour);
  cursor: pointer;
  outline: none;
}
.contact__social-icon-s:hover {
  color: #5c003a;
}


/************************************************/
/************** Contact Box *********************/
/************************************************/
#contact__contact-box {
  width: 40%;
  background-color: var(--lightGreyColour);
  margin: auto;
  transition: width 0.3s ease;
}
#contact__contact-content {
  margin: 40px auto 60px;
  padding: 50px 35px 70px;
  color: var(--textDarkColour);
  line-height: 1.7;
}
#contact__text-title {
  font-weight: 600;
  margin-bottom: 15px;
}
#contact__contact-email {
  color: rgba(211, 0, 134, 1);
  border-bottom: 2px solid rgba(211, 0, 134, 1);
  text-decoration: none;
}
/* #contact__contact-email:hover {
  background-color:rgba(211, 0, 134, 0.5);
  color: var(--textLightcolour);
} */


/************************************************/
/**************** Decoration *******************/
/************************************************/
#contact__decoration-l, #contact__decoration-r {
  position: absolute;
  z-index: -1;
  opacity: 0.8;
}
#contact__decoration-l {
  top: 140px;
  left: 60px;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}
#contact__decoration-r {
  top: 30px;
  right: 60px;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}


/************************************************/
/****************** Media ***********************/
/************************************************/
@media(max-width: 900px) {
  #contact__contact-container {
    width: 80%;
  }
  #contact__social-container {
    width: 80%;
    margin-left: 20%;
  }
  #contact__social-container-box {
    width: 75%; /** fix **/
  }
  #contact__contact-box {
    width: 60%;
  }
}

@media(max-width: 500px) {
  #contact-page {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #contact__contact-container {
    width: 100%;
  }
  #contact__contact-content {
    margin: 20px auto 40px;
  }
  #contact__contact-text {
    margin: auto;
  }
  #contact__social-container {
    width: 100%;
    margin-left: 0;
  }
  #contact__social-container-box {
    width: 100%; /** fix **/
  }
  #contact__contact-box {
    width: 100%;
  }

  #contact__decoration-l {
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
  }
  #contact__decoration-r {
    top: 80px;
    right: 0px;
    -webkit-transform: rotate(20deg)scale(0.6, 1.3);
        -ms-transform: rotate(20deg)scale(0.6, 1.3);
            transform: rotate(20deg)scale(0.6, 1.3);
  }
}

@media(max-width: 360px) {
  #contact__contact-container {
    height: 70px;
  }
  #contact__contact-content {
    margin: 30px auto;
    padding: 30px 10% 50px;
  }
  #contact__social-container, #contact__social-container-box {
    height: 70px;
  }
  .contact__social-icon-s {
    font-size: 35px;
  }
}