#hero-section {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
}

/***************** Text in the about section *************************/
#hero-text-container {
  position: absolute;
  color: var(--textLightcolour);
  /* margin-top: 120px; */
  left: 0;
  top: 45%;
  transform: translate(0, -50%);
  z-index: 2;
  width: 500px;
  margin-left: 100px;
  z-index: 2;
  overflow: hidden;
  /* border: 3px solid yellow; */
}
.name {
  font-size: 85px;
  font-family: 'Della Respira', serif;
  font-weight: 400;
  text-shadow: 4px 4px 0px rgba(78, 27, 52, 0.3);
  mix-blend-mode: screen;
  cursor: default;
  z-index: 2;
  overflow: hidden;
}
.personal-description {
  font-size: 18px; /*1.25em;*/
  font-weight: 200;
  z-index: 2;
  overflow: hidden;
  margin-left: 0px;
  width: 90%;
}

/******************** Viz in the header *****************************/
#header-viz-container {
  position: absolute;
  top: 50px;
  left: -50px;
  width: 100%;
  text-align: right;
  z-index: -1;
}
#header-viz-container svg {
  overflow: visible;
}


/***************************** Menu  *********************************/
#hero-menu-container {
  margin-top: 40px;
  width: 340px;
  margin-left: 100px;
  color: var(--lightColour);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  z-index: 2;
}
.menu-item {
  padding: 10px;
  text-decoration: none;
  margin-right: 0px;
  cursor: default;
  -webkit-transition: background-color 1s; /* For Safari 3.0 to 6.0 */
  -o-transition: background-color 1s;
  transition: background-color 1s; /* For modern browsers */
  cursor: pointer;
}
.menu-item:hover, .menu-item:active {
  background-color: rgba(211, 0, 134, 0.5);
}
.menu-underline {
  background-color: var(--purpleColour);
  height: 10px;
  margin-left: 100px;
  width: 350px;
  -webkit-filter: url(#static-noise-hero);
  filter: url(#static-noise-hero);
  opacity: 1;
}

/****************************************************************/
/******************** Media Queries *****************************/
/****************************************************************/

/* Tall (portrait) mobile screens - push content just low enough to have a full-page hero page */
/* very tall phones, or both portrait and landscape mode big screens  */
@media (min-height: 750px) and (orientation: portrait) {
  #hero-section {
    /* margin-bottom: 236px; */
  }  
}
@media (min-height: 900px) {
  #hero-section {
    /* margin-bottom: 210px; */
  }  
}
/* medium size mobile phones  */
@media (min-height: 650px) and (max-height: 750px) and (orientation: portrait) {
  #hero-section {
    /* margin-bottom: 150px; */
  }  
}
/* smaller size mobile phones  */
@media (min-height: 500px) and (max-height: 650px) and (orientation: portrait) {
  #hero-section {
    /* margin-bottom: 180px; */
  }  
}


/* width-based mostly for big screens */
@media(max-width: 1100px) {
  #hero-text-container {
    /* margin-top: 150px; */
    width: 400px;
  }  
  .name {
    font-size: 65px;
    line-height: 1.1;
  }
  #header-viz-container {
    top: 60px;
  }
}

@media(max-width: 850px) {
  #hero-text-container, #hero-menu-container, .menu-underline {
    margin-left: 75px;
  } 
  #hero-text-container {
    /* margin-top: 100px; */
    width: 300px;
  }  
  #header-viz-container {
    left: 0px;
    top: 80px;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
  }
}

@media(max-width: 650px) {
  #hero-text-container, #hero-menu-container, .menu-underline {
    margin-left: 50px;
    margin-right: 70px;
  } 
  #hero-text-container {
    /* margin-top: 190px; */
    width: 450px;
  }
  .name {
    margin-bottom: 10px;
  }
  .personal-description {
    font-size: 1.0em;
  }
  #header-viz-container {
    top: 100px;
  }
}

/* Very large phones or small tablets */
@media(max-width: 550px) {
  #hero-text-container {
    /* margin-top: 150px; */
    width: 350px;
  } 
  #hero-menu-container, .menu-underline {
    font-size: 14px;
    width: 80%;
    margin-left: 10%;
  }
  .menu-item {
    padding: 8px;
    margin-right: 0px;
  }
  .name {
    line-height: 1.2;
    margin-bottom: 15px;
  }
  #header-viz-container {
    left: -30px;
  } 
}

/* Medium-large phones like the Pixel 2 XL */
@media(max-width: 420px) {
  #hero-text-container {
    margin-left: 40px;
    top: 60%;
    /* margin-top: 200px; */
    width: 300px;
  }
  #hero-menu-container, .menu-underline {
    width: 100%;
    margin-left: 0;
  }
  #hero-menu-container {
    margin-top: 18px;
  }
  #header-viz-container {
    top: 100px;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
  }
  .menu-item {
    margin-right: 0px;
  }
  .name {
    font-size: 55px;
  }
}

/* Small phone like older iPhones*/
@media(max-width: 360px) {
  #hero-text-container {
    margin-left: 30px;
    /* margin-top: 150px; */
  } 
  #hero-menu-container {
    margin-top: 15px;
  }
  #header-viz-container {
    left: 10px;
    top: 60px;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
  }
  .name {
    font-size: 50px;
  }
}

/* very small phone like the Galaxy Fold */
@media(max-width: 320px) {
  #hero-text-container {
    width: 260px;
  } 
  #hero-menu-container {
    font-size: 12px;
  }
  .name {
    font-size: 45px;
  }
}

.hero-highlight {
  background-color: var(--purpleColour);
  font-weight: 500;
  padding: 0px 6px;
}
.bold {
  font-weight: 500;
}
