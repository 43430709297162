#portfolio-container-background {
  width: 100%;
  background-color: var(--lightGreyColour);
}
#portfolio-container {
  max-width: var(--maxWidthSections);
  margin: auto;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 100px;
  background-color: var(--lightGreyColour);
  color: var(--textDarkColour);
}
.clarifying-message {
  max-width: var(--maxWidthSections);
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  color: var(--textMediumColour);
  font-size: 12px;
  cursor: default;
}


/*************************************/
/******** Observable Notebooks *******/
/*************************************/
.observable-container {
  width: 65%;
  margin: auto;
  text-align: left;
  margin-bottom: 25px;
  font-size: 18px;
}
.observable-container button {
  font-size: 18px;
  border: none;
  border-bottom: 3px solid var(--purpleColour);
  padding: 6px;
  box-sizing: border-box;
  cursor: crosshair;
  transition: all 0.5s ease;
  background: none;
}
.observable-container button:hover, .observable-container button:active {
  background: var(--purpleColour);
  color: var(--lightColour);
}
@media (max-width: 780px) {
  .observable-container button {
    margin-top: 8px;
  }
}


/*************************************/
/**********  Portfolio Grid **********/
/*************************************/
.portfolio-grid {
  display: grid;
  width: 65%;
  margin: auto;
  text-align: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin-bottom: 80px;
}
.portfolio-grid.smaller-projects {
  margin-top: -80px;
}
.portfolio-grid-entry-wrapper {
  width: 100%;
  /* background-color: white; */
  align-self: center;
  justify-self: center;
}

.portfolio-grid-entry {
  width: 100%;
  height: 280px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover; 
}
.portfolio-grid-entry.small-project, .portfolio-grid-entry.small-contribution {
  height: 140px;
}


/*************************************/
/*********** Project Details *********/
/*************************************/

.popup-wrapper {
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 100%;
  max-width: 600px;
}


/*************************************/
/********  Portfolio Grid Btn  *******/
/*************************************/

.portfolio-grid-btn {
  height: 100%;
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transition: background-color 2s;
  -o-transition: background-color 2s;
  transition: background-color 2s; 
  outline: none;
  border: none;
  font-size: 20px;
  color: white;
  /* currently these break firefox
  -webkit-filter: url(#static-noise);
  filter: url(#static-noise);
  */
}
.portfolio-grid-btn:hover {
  opacity: 0.8;
}
.full-project:hover {
  background-color: var(--purpleColour);
}
.individual-viz:hover {
  background-color: var(--blueColour);
}

.portfolio-grid-project-title {
  position: absolute;
  bottom: 68%;
  left: 50%; 
  transform: translate(-50%,0);
  width: 100%;
  padding: 5px;
  text-shadow: 1px 1px 0px rgba(184, 184, 184, 0.4);
  mix-blend-mode: screen;
  cursor: default;
}

.border-button {
  text-transform: uppercase;
  outline: none;
  padding: 20px 25px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 3px var(--lightGreyColour);
  transition: box-shadow 0.6s linear;
  margin: 0.5em; 
  -webkit-transition: background-color box-shadow 1s;
  -o-transition: background-color box-shadow 1s;
  transition: background-color box-shadow font-weight 1s; 
}
.border-button:hover { 
  background-color: white;
  opacity: 1 !important;
}
.individual-viz-btn {
  border: 3px solid var(--blueColour);
}
.individual-viz-btn:hover {
  color: var(--blueColour);
  box-shadow: 0px 0px 0px 10px var(--blueColour); 
}
.full-project-btn {
  border: 3px solid var(--purpleColour);
}
.full-project-btn:hover {
  color: var(--purpleColour);
  box-shadow: 0px 0px 0px 10px var(--purpleColour); 
}

/*************************************/
/********** Project Details **********/
/*************************************/
.wrapper-project-details {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}
.project-details {
  text-align: left;
}
.project-title {
  font-family: 'Della Respira', serif;
  color: var(--textDarkColour);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.project-client {
  color: #82737D; 
  margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 0.8rem;
  font-style: italic;
}
.project-tools, .project-awards {
  font-size: 0.8rem;
  font-weight: 300;
  color: #82737D; 
}
.no-awards {
  color: var(--textLightcolour);
}



/*************************************/
/************* Container ************/
/*************************************/
#portfolio__projects-container-styles, #portfolio__standalone-container-styles {
  width: 65%;
  margin: auto;
  text-align: left;
}
.section-subtitle {
  width: 65%;
  color: var(--textMediumColour);
  margin: 20px auto 30px;
}
#portfolio__projects-container-styles {
  border-left: 10px solid var(--purpleColour);
  /* border: 1px solid red; */
}
#portfolio__standalone-container-styles {
  border-left: 10px solid var(--blueColour);
}
#portfolio__projects-text-styles, #portfolio__standalone-text-styles {
  color: var(--textMediumColour);
  font-family: 'Della Respira', serif;
  mix-blend-mode: screen;
  cursor: default;
  padding: 8px 8px 8px 20px;
}
#portfolio__projects-text-styles {
  text-shadow: 2px 2px 0px rgba(78, 27, 52, 0.3);
}
#portfolio__standalone-text-styles {
  text-shadow: 2px 2px 0px rgba(40, 99, 128, 0.3) /*rgba(13, 131, 131, 0.3) */;
}

/*************************************/
/*************  Media  ***************/
/*************************************/
@media(max-width: 1500px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 80%;
  }
}
/* @media(max-width: 1200px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 85%;
  }
} */
@media(max-width: 1100px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 80%;
  }
  .portfolio-grid-entry {
    height: 250px;
  }
}
@media(max-width: 1000px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 85%;
  }
  .portfolio-grid-entry {
    height: 260px;
  }
}
@media(max-width: 900px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 90%;
  }
  .portfolio-grid-entry {
    height: 240px;
  }
}
@media(max-width: 1050px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 85%;
    grid-template-columns: repeat(2, 1fr);
  }
  .portfolio-grid-entry {
    height: 280px;
  }
}
@media(max-width: 700px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 90%;
  }
  .portfolio-grid-entry {
    height: 260px;
  }
}
@media(max-width: 600px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 95%;
  }
  .portfolio-grid-entry {
    height:220px;
  }
}
@media(max-width: 500px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 95%;
  }
  .portfolio-grid-entry {
    height:200px;
  }
  .portfolio-grid-project-title {
    /*top: 10%;*/
  }
}
@media(max-width: 700px) {
  .portfolio-grid, #portfolio__projects-container-styles, #portfolio__standalone-container-styles, .observable-container, .section-subtitle  {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }
  .portfolio-grid-project-title {
    /*top: 13%;*/
  }
}

/* Video */
.video-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute; 
  top: 0;
  left: 0;
  
}
.video-wrapper video {
  object-fit: cover;
  height: 100%; 
  width: 100%;
}

/***************************/
/* Filter and Explain Text */
/***************************/
.wrapper-filter-and-explain-text {
  font-size: 0.9rem;
  font-weight: 200;
}
.wrapper-filter-and-explain-text button {
  border: none;
  font-size: 0.75rem;
  font-weight: 200;
  margin-left: 0.3rem;
  background: none;
  cursor: crosshair;
  font-family: 'Poppins', sans-serif;
}
.wrapper-filter-and-explain-text button:hover,  .wrapper-filter-and-explain-text button.active {
  color: var(--purpleColour);
  border-bottom: 1.2px dashed var(--purpleColour);
}
.filter-link {
  /* margin-left: 0.5rem; */
  /* padding: 0.1rem; */
  margin: 0 0.2rem;
  padding: 0 0.2rem;
  font-weight: 400;
  cursor: crosshair;
  border-bottom: 1.2px dashed #82737D; 
  position: relative;
  /* display: inline-block; */
  transition: all 0.5s ease;
}
.filter-link:hover,  .filter-link.active {
  color: var(--purpleColour);
  border-bottom: 1.2px dashed var(--purpleColour);
}
