/*
.arrow__container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
}
*/

.arrow-up {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  font-size: 57px;
  color: var(--lightPurpleColour);
  padding: 15px 5px 15px 15px;
}