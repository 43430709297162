:root {
  --writing-dark-colour: #1e1c1f;
  --writing-third-accent-colour: #48444b;
}

/* container for the whole page */
#writing__page-container {
  max-width: var(--maxWidthSections);
  margin: auto;
  position: relative;
  text-align: center;
  color: var(--textMediumColour);
  padding-top: 50px;
  /* background-color: var(--writing-dark-colour); */
  overflow: hidden; /* TODO: this is a hack; should check why there is an overflow at below 600px*/
  min-height: 100vh;
}

/***********************************************/
/************* Content Container ***************/
/***********************************************/
#writing__content-container {
  width: 77%;
  color: var(--lightGreyColour);
  margin: auto;
  margin-bottom: 50px;
  position: relative;
}


/*************************************************/
/********* Title and Explanation Text ************/
/*************************************************/
#writing__title-text {
  font-family: 'Della Respira', serif;
  text-shadow: 4px 4px 0px rgba(78, 27, 52, 0.3);
  mix-blend-mode: screen;
  cursor: default;
}
.writing__explanation {
  text-align: left;
  line-height: 2;
  font-size: 18px;
}
.writing__explanation span {
  color: var(--textLightcolour);
  padding: 3px;
  cursor: default;
}
.writing__explanation span.resource {
  background-color: var(--purpleColour);
}
.writing__explanation span.example {
  background-color: var(--blueColour);
}
.writing__explanation span.tutorial {
  background-color: var(--writing-third-accent-colour);
}


/*************************************************/
/************ Blog Preview Posts *****************/
/*************************************************/
/* container for all the posts */
#writing__post-preview-container {
  margin-top: 70px;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
/* container for each individual post*/
.blog-preview-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-bottom: 50px;
}
/* img for each individual post */
.blog-preview-img {
  min-height: 300px;
  background-color: grey;
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover; 
  cursor: pointer;
  -webkit-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}
.blog-preview-img:active {
  -webkit-transform: scale(0.97);
      -ms-transform: scale(0.97);
          transform: scale(0.97);
}
/* content for each individual post */
.blog-preview-content {
  background-color: var(--lightGreyColour);
  margin-left: 20px;
  padding: 20px 30px;
  -webkit-box-flex: 4;
  -ms-flex: 4;
      flex: 4;
  border-right: 15px solid white; /* colour is just a placeholder */
  overflow: auto;
  -webkit-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.blog-preview-title {
  font-weight: bold;
  font-family: 'Della Respira', serif;
  color: var(--textDarkColour);
  cursor: pointer;
  -webkit-transition: background-color 1s; /* For Safari 3.0 to 6.0 */
  -o-transition: background-color 1s;
  transition: background-color 1s; /* For modern browsers */
}
.blog-preview-title:hover {
  background-color: var(--writing-dark-colour); /* colour is just a placeholder */
  color: var(--textLightcolour);
}
.blog-preview-date {
  color: #666666;
  margin-top: 3px;
  cursor: default;
}
.blog-preview-summary {
  margin-top: 10px;
  color: var(--textDarkColour);
  line-height: 1.5;
  font-size: 16px;
  font-weight: 300;
}
.blog-preview-btn {
  outline: none;
  border: none;
  color: var(--textLightcolour);
  font-size: 16px;
  padding: 10px 25px;
  text-transform: uppercase;
  margin-top: 15px;
  cursor: pointer;
}
.blog-preview-btn:hover {
  opacity: 0.8;
}
.blog-preview-btn:active {
  transform: scale(0.97);
}

/************************************************/
/******************** Media  ********************/
/************************************************/

@media(max-width: 1300px) {
  .blog-preview-img, .blog-preview-content {
    height: 380px;
  }
}
@media(max-width: 1100px) {
  #writing__page-container {
    padding-top: 30px;
  }
  .blog-preview-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 60px;
  }
  .blog-preview-img {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-height: 250px;
  }
  .blog-preview-content {
    margin-left: 0;
    padding: 20px 30px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
@media(max-width: 600px) {
  #writing__content-container {
    width: 100%;
  }
  /* 
    need to manually add margin on both sides if the width of the container is 100%;
    if the width of the whole container is 90%, delete this rule
  */
  #writing__title-container, .writing__explanation{
    margin-left: 5%;
    margin-right: 5%;
  }
  #writing__post-preview-container {
    margin-top: 50px;
  }
  .blog-preview-container {
    margin-bottom: 80px;
  }
  /* content for each individual post */
  .blog-preview-content {
    margin-left: 0px;
    padding: 20px 25px;
    }
}
