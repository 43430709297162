@import url("https://use.typekit.net/fbk6azk.css");


:root {
  --elex-col-light: #E9EFF5;
  --elex-col-dark: #9099a3;
  --elex-col-darker: #2b2e31;
  --font-fancy:  "cormorant-infant", sans-serif; /*  "active", sans-serif; */
  --font-default: "cormorant-infant", sans-serif;
}

#us-elections-container {
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 1rem;
}

#us-elections-container .code {
  background-color: var(--elex-col-light);
  color: var(--elex-col-darker);

}

#us-elections-container a {
  text-decoration: underline;
  color: var(--elex-col-dark);
  font-family: var(--font-default);
  padding: 0 2px;
  transition: all 0.5s ease;
}

#us-elections-container a:hover {
  background-color: var(--elex-col-light);
  /* text-decoration: none; */
}

#us-elections-container .bold {
  font-weight: bold;
}

/******************************************/
/***************** Crown *****************/
/******************************************/

#us-elections-container .crown-wrapper {
  margin: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: var(--font-default);
  color: var(--elex-col-darker);
  font-size: 1.6rem;
  width: 100%;
  max-width: 1000px;
}

@media (max-width: 700px) {
  #us-elections-container .crown-wrapper {
    margin: 2rem;
    font-size: 1.4rem;
  }
}

#us-elections-container .dek {
  text-align: center;
  width: 100%;
  max-width: 450px;
  color: var(--elex-col-dark);
}

#us-elections-container h1 {
  /* font-size: 7rem; */
  font-size: clamp(3rem, 10vw, 7rem);
  font-family: var(--font-default);
  color: var(--elex-col-darker);
  z-index: 3;
  line-height: 1;
  margin-bottom: 3rem;
}

#us-elections-container .crown-wrapper video {
  width: 100%;
  max-width: 550px;
  margin-bottom: 50px;
  transform: rotate(-10deg);
}

#us-elections-container .project-details {
  text-align: left;
}

#us-elections-container div.text {
  color: var(--elex-col-darker);
}

/******************************************/
/************* Section titles *************/
/******************************************/

#us-elections-container .section-title {
  margin: 4rem auto;
  font-size: 3rem;
}

#us-elections-container .map-view-title {
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 1.2rem;
  text-align: center;
  font-family: var(--font-default);
}




/******************************************/
/* Map view gallery - single view gallery */
/******************************************/
#us-elections-container .grid-container {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: 10px; 
  width: 420px;
  margin-bottom: 10px;
}

@media (max-width: 1400px) and (min-width: 1100px) {
  #us-elections-container .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    width: 380px;
  }
}

#us-elections-container .grid-container .grid-container.bottom {
}

#us-elections-container .grid-container .grid-item {
  width: 133px;
  height: 87px; 
  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #D2DDE8;
  transition: all 0.3s ease-in-out;
  cursor: url('cursor-expand.png'), crosshair;
}

@media (max-width: 1400px) and (min-width: 1100px) {
  #us-elections-container .grid-container .grid-item {
    width: 110px;
    height: 66px; 
  }
}

#us-elections-container .grid-container .grid-item:hover {
  box-shadow: 0px 6px 8px #e0e0e0;
}

#us-elections-container .grid-container .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  border-radius: 1px;
}

#us-elections-container .main-image-container {
  width: 100%;
  max-width: 500px;
  max-height: 325px;
  overflow: hidden; /* Ensures no part of the image overflows */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-bottom: 40px;
  margin-top: 40px;
  /* border: 2px solid #000000; */
  /* box-shadow: 2px 8px 6px #e3e3e3; */
  cursor: url('cursor-expand.png'), crosshair;
}

#us-elections-container .main-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1); 
}

/******************************************/
/** Overlay image when image is clicked ***/
/******************************************/
#us-elections-container .overlay-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d2dde8a8;
  backdrop-filter: blur(10px); /* Blur effect */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: url('cursor-decrease.png'), crosshair;
}

@media (max-width: 1000px) {
  #us-elections-container .overlay-image-container {
    display: none;
  }
}

#us-elections-container .overlay-image-container img {
  width: 1000px;
  height: 650px;
  border-radius: 10px;
}


/******************************************/
/**************** Text WEll ***************/
/******************************************/
#us-elections-container .text-well {
  width: 100%;
  color: var(--elex-col-darker);
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#us-elections-container .text-well h2, 
#us-elections-container .text-well h3, 
#us-elections-container .text-well h4,
#us-elections-container .text-well p,
#us-elections-container .text-well ul,
#us-elections-container .text-well div.text
 {
  max-width: 500px;
  /* z-index: 100; */
}
#us-elections-container .text-well h3 {
  /* margin: 2rem auto 0rem; */
}
#us-elections-container .text-well div.text {
  margin: 1rem auto;
}
#us-elections-container h2 {
  font-size: 2.2rem;
  color: var(--elex-col-darker);
  font-family: var(--font-fancy);
  text-align: left;
  width: 100%;
  max-width: 500px;
  /* margin-bottom: 3rem; */
}
#us-elections-container h3 {
  width: 100%;
  text-align: start;
  font-size: 1.6rem;
  font-family: var(--font-fancy);
}
#us-elections-container h4, #us-elections-container .listing {
  width: 100%;
  text-align: start;
  font-size: 1.3rem;
}

#us-elections-container .highlight {
  font-weight: bold;
}

#us-elections-container .text-well p, 
#us-elections-container .text-well div, 
#us-elections-container .text-well ul, 
#us-elections-container .text-well li {
  font-size: 1.3rem;
  letter-spacing: 1.1;
  line-height: 1.4;
  font-family: var(--font-default);
}

#us-elections-container .text-well li {
  margin-bottom: 1rem;
}

ul {
  list-style: none; 
  padding: 0;
}
li {
  position: relative;
  padding-left: 20px; 
}
li::marker {
  content: '●'; 
  font-size: 12px; 
  color: #9099a3; 
}

#us-elections-container .logo-icon {
  width: 14px;
  padding: 0;
  margin: 0 3px 0 3px;
}

#us-elections-container .tech-name {
  font-weight: bold;
}


/******************************************/
/************* VIDEO elements *************/
/******************************************/
#us-elections-container video {
  border-radius: 5px;
  box-shadow: 0px 5px 15px #D2DDE8;
  margin: 2rem auto;
  transform: rotate(2deg);
}

#us-elections-container .container-tooltips-videos {
  display: flex;
  flex-direction: row;
}

@media (max-width: 600px) {
  #us-elections-container .container-tooltips-videos {
    flex-direction: column;
  }
}


#us-elections-container .container-tooltips-videos video {
  margin: 20px;
}

#us-elections-container .container-cartograms-images {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

@media (max-width: 1200px) {
  #us-elections-container .container-cartograms-images {
    flex-direction: column;
  }
}

#us-elections-container .container-cartograms-images img {
  width: 100%;
  max-width: 600px;
}

#us-elections-container .container-cartograms-images.state img {
  width: 100%;
  max-width: 400px;
}

#us-elections-container video.summary-video {
  width: 100%;
  max-width: 550px;
}


#us-elections-container video.a11y-video {
  width: 100%;
  max-width: 700px;
  transform: rotate(0deg);
}

#us-elections-container video.custom-interactions-video {
  width: 100%;
  max-width: 400px;
  transform: rotate(-3deg);
}

#us-elections-container video.video-responsive {
  width: 100%;
  max-width: 450px;
  transform: rotate(4deg);
}

#us-elections-container video.special-elex-video {
  width: 100%;
  max-width: 370px;
  transform: rotate(3deg);
}



/******************************************/
/**** Map view gallery - whole galery *****/
/******************************************/
#us-elections-container .wrapper-map-rail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem auto 7rem;

}

@media (max-width: 1100px) {
  #us-elections-container .wrapper-map-rail {
    flex-wrap: wrap;
  }
}

#us-elections-container .wrapper-map-view-gallery {
  width: 100% !important;
  position: relative;
}

/******************************************/
/**** Map view gallery - small grid *****/
/******************************************/
#us-elections-container .images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 10px; 
  width: 100%;
  max-width: 1100px;
  margin-bottom: 3px;
  margin: 3rem auto;
  place-items: center; /* Centers the grid items */
}

#us-elections-container .images-grid.dotcom {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  width: 100%;
  max-width: 820px;
  gap: 20px; 
}

@media (max-width: 450px) {
  #us-elections-container .images-grid.dotcom {
    margin-left: -12px;
  }
}

#us-elections-container .images-grid .grid-item {
  width: 360px;
  height: 200px;
  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: crosshair;
}

#us-elections-container .images-grid.dotcom .grid-item {
  width: 400px;
  height: 233px;
  border-radius: 0px;
  margin: 0;
}

@media (max-width: 450px) {
  #us-elections-container .images-grid.dotcom .grid-item {
    width: 360px;
    height: 220px;
  }
}


#us-elections-container .images-grid .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1); 
  transition: transform 0.5s ease; /* smooth zoom effect */
  cursor: crosshair;
  margin: 0;
  padding: 0;
}

#us-elections-container .images-grid .grid-item img:hover {
  transform: scale(2); /* adjust scale value for zoom level */
}

#us-elections-container .wrapper-metrics {
  display: flex;
  align-items: center;
  justify-content: center;
}

#us-elections-container .wrapper-metrics div {
  width: 300px;
}

#us-elections-container img.img-lighthouse {
  width: 100%;
  max-width: 250px;
  margin: 1rem;
  border-radius: 5px;
}

@media (max-width: 700px) {
  #us-elections-container .wrapper-metrics {
    flex-direction: column;
  }
}

