@import url('https://fonts.googleapis.com/css2?family=Della+Respira&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

:root {
  --blueColour: #0096a1; /* #1B3A4B #6100df #0096a1*/
  --purpleColour: #d30086; /* #4E1B34 */
  --lightPurpleColour: #2bfff8; /* #771D43 */
  --darkColour: #0A1014;
  --lightColour: #ebebeb;
  --textLightcolour: #ebebeb;
  --lightGreyColour: #E5E5E5;
  --textMediumColour: #292728dc;
  --textDarkColour: #0A1014;
  --maxWidthSections: 1800px;
  --textUnderlineColour: #B8B8B8;
  --darkBackgroundColour: #000000; /* #141115 */
}
body {
  background-color: var(--darkBackgroundColour);/* #161616; #E5E5E5;*/
  color: var(--textLightcolour);
  font-family: 'Poppins', sans-serif;
}
body, * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;  
}
*:active, *:hover, *:focus {
  outline: none;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

::selection {
  background: rgba(255, 255, 255, 0.8); 
  color: var(--textDarkColour);
}
::-moz-selection {
  background: rgba(255, 255, 255, 0.9); /* Gecko Browsers */
}

/*
.highlight-dark-background::selection {
  background: rgba(78, 27, 52, 0.5); 
}
.highlight-dark-background::-moz-selection {
  background: rgba(78, 27, 52, 0.5); 
}
.highlight-blue-background::selection {
  background: rgba(21, 19, 21, 0.5); 
}
.highlight-blue-background::-moz-selection {
  background: rgba(21, 19, 21, 0.5); 
}
*/

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(248, 248, 248);
  border: 0;
}

::-webkit-scrollbar-track {
  opacity: 0.3;
}

/********************************/
/************ Links *************/
/********************************/
a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}
.link-project {
  text-decoration: underline;
  font-weight: 600;
}
.link-project:hover {
  background-color: white;
}


/********************************/
/************ Text **************/
/********************************/
.large-text {
  font-size: 46px;
}
.medium-text {
  font-size: 26px;
}
.body-text-large {
  font-size: 18px;
}
.body-text-normal {
  font-size: 16px;
}


/****************************************/
/******** Fancy Text Underline **********/
/****************************************/
.underline-gradient span {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(255,0,255,0)), color-stop(16%, rgba(255,0,255,0)), color-stop(16%, var(--textUnderlineColour)), color-stop(41%, var(--textUnderlineColour)), color-stop(41%, rgba(255,0,255,0)));
  background: -o-linear-gradient(bottom, rgba(255,0,255,0) 0%, rgba(255,0,255,0) 16%, var(--textUnderlineColour) 16%, var(--textUnderlineColour) 41%, rgba(255,0,255,0) 41%);
  background: linear-gradient(0deg, rgba(255,0,255,0) 0%, rgba(255,0,255,0) 16%, var(--textUnderlineColour) 16%, var(--textUnderlineColour) 41%, rgba(255,0,255,0) 41%);
}
.underline-padding span {
  padding: 0.9em 0.1em 0.7em 0.1em;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}


/***********************************************************/
/********* Title with vertical line on the left  ***********/
/***********************************************************/
.title-container {
  margin: auto;
  position: relative;
  color: var(--lightColour);
  border-left: 10px solid var(--purpleColour);
  opacity: 1;
  z-index: 2;
  /*-webkit-filter: url(#glow-generic);*/
  /*filter: url(#glow-generic);*/
  margin-bottom: 20px;
}
.title-text {
  font-weight: 300;
  margin: auto;
  text-align: left;
  padding: 20px 20px;
}


/****************************************/
/********** Decorative SVGs *************/
/****************************************/
.card-purple {
  fill: var(--purpleColour);
}
.card-light-purple {
  fill: var(--lightPurpleColour);
}
.card-blue {
  fill: var(--blueColour);
}
.card-light {
  fill: var(--lightColour);
}


/*********************************/
/************ Media *************/
/*********************************/
@media(max-width: 600px), (max-height: 500px) {
  .large-text {
    font-size: 36px;
  }
  .medium-text {
    font-size: 26px;
  }
  .body-text-large {
    font-size: 18px;
  }
}

@media(max-width: 400px), (max-height: 500px) {
  .large-text {
    font-size: 28px;
  }
}