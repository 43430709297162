#about__page-container {
  max-width: var(--maxWidthSections);
  margin: auto;
  position: relative;
  margin-top: 1px; /****** CHANGE *******/
  text-align: center;
  color: var(--textMediumColour);
  padding-top: 10px;
  background-color: var(--darkBackgroundColour);
  overflow: hidden; /* TODO: this is a hack; should check why there is an overflow at below 600px*/
}

/***********************************************/
/*************** About Section *****************/
/***********************************************/
#about__about-container {
  width: 79%;
  color: var(--lightGreyColour);
  margin: auto;
  margin-bottom: 50px;
  position: relative;
  border: 0px solid white;
  line-height: 1.8;
  font-weight: 200;
}
#about__about-text {
  text-align: left;
  margin-left: 0%;
  margin-right: 50%;
  padding: 20px;
  /*max-height: 530px;*/
  overflow: auto;
  font-size: 18px;
  /* background-color: rgba(255,255,255,0.01);
  border-top: 7px solid rgba(255,255,255,0.01);
  border-bottom: 7px solid rgba(255,255,255,0.01); */
}
#about__about-photo {
  position: absolute;
  top: 50px;
  right: 26%;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
#about__about-title-container {
}
#about__about-title-text {
  font-family: 'Della Respira', serif;
  text-shadow: 4px 4px 0px rgba(78, 27, 52, 0.3);
  mix-blend-mode: screen;
  cursor: default;
}
.about-me-text span{
  font-weight: 600;
}


/***********************************************/
/************ CV Timeline section ***************/
/***********************************************/
#about__cv-title {
  color: var(--textLightcolour);
  width: 300px;
  margin: auto;
  margin-bottom: 50px;
}
#about__cv-title h2 {
  font-weight: 600;
}
#about__cv-title p {
  border-bottom: 0px solid var(--purpleColour);
}
.about__linkedin-icon {
  font-size: 18px;
  color: var(--lightColour);
  cursor: pointer;
  outline: none;
  margin: 0 7px;
}
.about__linkedin-icon:hover {
  color: #DDB9CA;
}

#about__cv-container {
  width: 80%;
  margin: auto;
  position: relative;
  height: 1350px;
  color: var(--textLightcolour);
}
#about__cv-title h2 {
  font-family: 'Della Respira', serif;
}
.timeline-line {
  background-color: var(--purpleColour);
}
.timeline-point {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background-color: var(--purpleColour);
  border: 2px solid var(--lightColour);
}
.timeline-info {
  cursor: default;
}
.date-line {
  background-color: var(--purpleColour);
}
.timeline-date {
  cursor: default;
  white-space: nowrap;
}
.timeline-info-extended {
  border: 2px solid var(--purpleColour);
  background-color: var(--lightGreyColour);
  text-align: left;
  padding: 14px 17px;
  opacity: 0;
  cursor: default;
}
.job-title {
  margin-bottom: 10px;
  font-weight: 600;
}
.job-title.gradient span {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(255,0,255,0)), color-stop(16%, rgba(255,0,255,0)), color-stop(16%, var(--purpleColour)), color-stop(41%, var(--purpleColour)), color-stop(41%, rgba(255,0,255,0)));
  background: -o-linear-gradient(bottom, rgba(255,0,255,0) 0%, rgba(255,0,255,0) 16%, var(--purpleColour) 16%, var(--purpleColour) 41%, rgba(255,0,255,0) 41%);
  background: linear-gradient(0deg, rgba(255,0,255,0) 0%, rgba(255,0,255,0) 16%, var(--purpleColour) 16%, var(--purpleColour) 41%, rgba(255,0,255,0) 41%);
}
.job-title.padding span {
  padding: 0.9em 0.1em 0.7em 0.1em;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.timeline-info-extended {
  color: var(--textMediumColour)
}
.workplace-name {
  font-style: oblique;
}
.workplace-location {
  color: #ebebebde;
}

/************************************************/
/******************** Media  ********************/
/************************************************/
@media(max-width: 1200px) {
  #about__about-text {
    /*height: 500px;*/
  }
}
@media(max-width: 1000px) {
  #about__page-container {
    padding-top: 20px;
  }
  #about__about-title-container {
    width: 100%;
    margin-left: 6%;
  }
  #about__about-photo {
    top: 100px;
    left: 20%;
  }
  #about__about-text {
    text-align: left;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 370px;
    /*padding-bottom: 70px;*/
    /*height: 600px;*/
  }
  #about__about-triangle {
    border-bottom: 100px solid var(--purpleColour);
    border-right: 100px solid transparent;
  }
}

@media(max-width: 600px) {
  #about__about-container {
    width: 90%;
    margin-bottom: 0px;
    margin-top: -30px;
  }
  #about__about-photo {
    top: 60px;
    left: 8%;
  }
  #about__about-text {
    margin-top: 270px;
    margin-bottom: 70px;
    /*height: 800px;*/
  }
}

@media(max-width: 400px) {
  #about__about-text {
    margin-top: 250px;
  }
}

/***** CV Timeline *******/
@media(max-width: 900px) {
  #about__cv-container {
    width: 50%;
    margin-top: auto;
    margin-left: 15%;
  }
}
@media(max-width: 650px) {
  #about__cv-container {
    width: 100%;
    margin-left: -35%;
  }
}
@media(max-width: 600px) {
  #about__cv-container {
    height: 1700px;
  }
}
@media(max-width: 360px) {
  #about__cv-container {
    margin-left: -40%;
  }
}
