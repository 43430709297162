.tooltip {
  opacity: 0;
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  padding: 5px 10px;
  background: #fff;
  border: 2px solid #fca311;
  z-index: 2;
  transition: all 0.5s ease-out;
  pointer-events: none;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

/* for the actual displayed bar chart */
.bar-chart-tooltip-wrapper {
  margin: auto !important;
  text-align: center !important;
  position: relative;
  width: 300px;
}