@import url('https://fonts.googleapis.com/css2?family=Della+Respira&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');


/*********************************************/
/***************  Wrapper  ********************/
/*********************************************/
.portfolio-page__wrapper {
  position: relative;
  max-width: var(--maxWidthSections);
  margin: 0 auto;
  background-color: var(--lightGreyColour);
  color: var(--textDarkColour);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 50px;
  min-height: 100vh;
}


/*********************************************/
/***************  Images  ********************/
/*********************************************/
.portfolio-page__image-background {
  width: 45%;
  height: 90vh;
  background-color: var(--lightGreyColour);
}
.portfolio-page__image {
  position: absolute;
  width: 50%;
  height: calc(90vh - 7vh - 15vh);
  top: 7vh;
  left: 2%;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover; 
}


/*********************************************/
/*****************  Text  *********************/
/*********************************************/
.portfolio-page__text {
  width: 50%;
  height: 100%;
  padding: 7% 7% 5% 10%;
  margin: auto;
  text-align: left;
}
.portfolio-page__title-text {
  font-family: 'Della Respira', serif;
  margin-bottom: 40px;
  font-weight: 600;
}
.portfolio-page__responsible-for-container, .portfolio-page__tools-container {
  margin-bottom: 10px;
}
.portfolio-page__bold-text {
  font-weight: bold;
}
.portfolio-page__project-description-container {
  padding: 0px 50px;
  margin-left: -50px;
  margin-top: -10px;
}


/*********************************************/
/***************  Buttons   ******************/
/*********************************************/
.portfolio-page__view-btn-container {
  margin-right: 50px;
  margin-top: 50px;
  margin-bottom: 40px;
}
.portfolio-page__view-btn {
  outline: none;
  padding: 10px 50px;
  width: 200px;
  text-transform: uppercase;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--lightColour);
  border: 6px solid;
  font-weight: 300;
  font-size: 20px;
  -webkit-transition: border 1s; /* For Safari 3.0 to 6.0 */
  -o-transition: border 1s;
  transition: border 1s; 
  cursor: pointer;
}
.portfolio-page__view-btn:hover, .portfolio-page__view-btn:focus, .portfolio-page__view-btn:active {
  color: var(--lightColour);
}
.portfolio-page__view-btn:hover {
  border: 6px solid white !important;
}


/*********************************************/
/*********  Media Queries ********************/
/*********************************************/

/* iPad Pro */
@media(max-width: 1050px){
  .portfolio-page__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .portfolio-page__image-background {
    width: 80%;
    height: 60vh;
  }
  .portfolio-page__image {
    width: 90%;
    height: calc(60vh - 3vh - 5vh);
    top: 3vh;
    left: 7%;
  }
  .portfolio-page__text {
    width: 100%;
    padding-left: 7%;
    padding-top: 30px;
    padding-right: 15px;
    padding-right: calc(100% - 90%)
  }
  .portfolio-page__view-btn {
    margin-top: -25px;
  }
  .portfolio-page__view-btn-container {
    margin-top: 0px;
    margin-bottom: 35px;
  }
}

@media(max-width: 800px) {
  .portfolio-page__image-background {
    width: 90%;
    height: 60vh;
  }
  .portfolio-page__image {
    width: 90%;
    height: calc(60vh - 3vh - 5vh);
    top: 3vh;
    left: 5%;
  }
}

@media(max-width: 500px) {
  .portfolio-page__image-background {
    height: 45vh;
  }
  .portfolio-page__image {
    height: calc(45vh - 2vh - 3vh);
    top: 2vh;
    left: 5%;
  }
  .portfolio-page__project-description-container {
    padding: 0px 0px 0px 50px;
  }
}

@media(max-width: 380px) {
  .portfolio-page__image {
    width: 90%;
    height: calc(45vh - 1vh - 2vh);
    top: 1vh;
    left: 5%;
  }
}

@media (min-aspect-ratio: 15/9)and (max-height: 600px) {
  .portfolio-page__wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .portfolio-page__image-background {
    width: 35%;
    height: 95vh;
  }
  .portfolio-page__image {
    position: absolute;
    width: 40%;
    height: calc(90vh - 7vh - 15vh);
    top: 7vh;
    left: 2%;
  }
  .portfolio-page__text {
    width: 65%;
    height: 100%;
    padding: 40px 20px 20px 90px;
    margin: auto;
    text-align: left;
  }
  .portfolio-page__view-btn {
    padding: 5px 30px;
    width: 100px;
  }
}

/* For the TDB project page*/
.tdb-image-full {
  width: 100%;
}